import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useStore } from 'global'
import { TextField, Button, SearchableSelect } from 'components'
import { KeyType } from 'enums'
import { toFriendlyDate, toFriendlyTime } from 'utils'

export default function EditKey(props) {
  const { mtiKey: key, onSuccess, onFinish } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { store } = useStore()
  const api = useAPI()

  const [isBackupKey, setIsBackupKey] = useState(key?.backupKey || false)
  const serialField = useRef()
  const userField = useRef()

  const generateKeyData = () => ({
    id: key?.id,
    backupKey: isBackupKey,
    serialNumber: key?.serialNumber || serialField.current.value.toUpperCase(),
    status: key ? key.status : 1,
    storeId: key?.storeId || store?.id,
    userId: userField.current?.value?.id || null,
  })

  const save = event => {
    event.preventDefault()
    api.saveKey(generateKeyData()).then(_ => {
      onSuccess?.()
      onFinish?.()
    }).catch(response => {
      const isInvalidated = response?.error?.error?.validationErrors?.serial_number?.[0] == 'has been invalidated'
      isInvalidated ? toast.error(t('error.saveInvalidatedKey')) : toast.error(t('error.saveKey'))
    })
  }

  const searchUsers = (searchText, pageNumber) => {
    const query = {
      name: searchText,
      store_id: store.id,
      page: pageNumber || 1,
    }
    api.getUsers(query).then(response => {
      const users = response?.users?.map(u => userWithName(u))
      userField.current.addData(users, response?.meta)
    }).catch(()=>{})
  }

  return (
    <div className='edit-key-comp'>
      <form className='col' onSubmit={save}>
        <h1>{!key ? t('key.createKey') : t('key.editKey')}</h1>
        {!key ?
          <div className='backup-key-container row'>
            <input id='backup-key' type='checkbox' value={isBackupKey} onChange={()=>setIsBackupKey(!isBackupKey)}/>
            <label htmlFor='backup-key'>{t('key.backupKey')}</label>
          </div>
          :
          <div>
            <p className='subtitle'>{key?.backupKey ? t('key.backupKey') : t('key.userKey')}</p>
            <p className='subtitle'>{t('key.createdAt', {date: toFriendlyDate(key?.createdAt), time: toFriendlyTime(key?.createdAt)})}</p>
            {key?.keyType === KeyType.rfid && <p className='subtitle'>{t('key.lastSeenAt', {date: toFriendlyDate(key?.lastSeenAt), time: toFriendlyTime(key?.lastSeenAt)})}</p>}
          </div>
        }
        <TextField
          ref={serialField}
          defaultValue={key?.serialNumber}
          label={t('key.keySerial')}
          disabled={!!key}
          minLength='8'
          maxLength='8'
          pattern='[a-fA-F0-9]+'
          title={t('key.permittedCharacters')}
        />
        {!isBackupKey && (!key || key.keyType == KeyType.rfid) &&
          <SearchableSelect
            ref={userField}
            onSearch={searchUsers}
            defaultValue={userWithName(key?.user)}
            label={t('key.assignedUser')}
          />
        }
        <div className='button-row row'>
          <Button outline onClick={onFinish}>{t('form.cancel')}</Button>
          {(!key || (!key?.backupKey && key?.keyType == KeyType.rfid)) && <Button type='submit'>{t('form.save')}</Button>}
        </div>
      </form>
    </div>
  )
}

function userWithName(user) {
  return user ? {
    ...user,
    name: [user.firstName, user.lastName].filter(n => n).join(' '),
  } : user
}