import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI, useStore } from 'global'
import { camelize, exportCSV } from 'utils'
import Screen from './screen'

export default function Reports() {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { store } = useStore()
  const [reports, setReports] = useState()
  const [pagination, setPagination] = useState()

  const queryRef = useRef({})
  const getReports = query => {
    query.storeID = store?.id
    queryRef.current = query
    api.getReport(query).then(response => {
      const { meta, results } = response[query.reportType]
      setReports(results?.map(normalize) || [])
      setPagination(meta)
    }).catch(()=>{})
  }

  const onExport = query => {
    if (!query) return exportCSV(toCSV(t, reports), getFilename(queryRef.current, true))
    return new Promise(r => getPageForExport({
      ...query,
      storeID: store?.id,
      per: -1,
      page: 1,
    }, [], r))
  }

  const getPageForExport = (query, previousData, resolve) => {
    api.getReport(query).then(response => {
      const { results, meta } = response[query.reportType]
      let allReports = previousData.concat(results?.map(normalize) || [])
      if (meta?.links?.next_page)
        getPageForExport({ ...query, page: Number(query.page) + 1 }, allReports, resolve)
      else {
        exportCSV(toCSV(t, allReports), getFilename(query))
        resolve()
      }
    }).catch(err => {
      console.error('Error fetching page for export all.', query, err)
      // If there's some error fetching this page, but we have results from previous pages, then at least export those
      if (previousData.length) exportCSV(toCSV(t, previousData), getFilename(query))
      resolve()
    })
  }

  if (!store) return <></>

  return (
    <Screen
      reports={reports}
      meta={pagination}
      onQuery={getReports}
      onExport={onExport}
    />
  )
}

function getFilename(query, withPage = false) {
  let f = `store-${query.storeID}_${query.reportType}_${query.startDate}_${query.endDate}`
  if (withPage) f = `${f}_page-${query.page}`
  return f
}

const systemEvents = [
  'alarming',
  'lifted',
]

function normalize(event) {
  const report = {
    action: camelize(event.event_type),
    blamedKey: event.key_serial_number || event.physical_key_serial_number, // event.physical_key_serial_number
    blamedUser: event.initiator,
    resourceName: event.position_name,
    zoneName: event.fixture_name,
    occurredAt: (new Date(event.occurred_at)).getTime(), // convert string to timestamp
    resourceType: event.resource_type,
    duration: event.duration,
    cause: event.event_message,
  }

  // We don't currently record/receive the user's role on the reports endpoint
  if (systemEvents.includes(report.action))
    report.blamedUserRole = 'system'

  if (report.resourceType == 'SecurityDevice') {
    report.resourceSerial = event.resource?.serial_number
    report.resourceSubtype = event.resource?.device_type
  } else if (report.resourceType == 'PhysicalKey') {
    report.blamedKey = null // If the resource was the key, like on the assigned key event, then don't also blame the key
    report.resourceSerial = event.resource?.serial_number
    report.resourceName = event.resource?.user
  }
  if (['physicalKeyUnassigned', 'physicalKeyAssigned'].includes(report.action))
    report.resourceName = event.key_serial_number || event.physical_key_serial_number
  return report
}

const csvOrder = [
  'blamedUser',
  'blamedKey',
  'action',
  'resourceType',
  'resourceName',
  'resourceSerial',
  'zoneName',
  'occurredAt',
  'duration',
]

function toCSV(t, data) {
  let csv = data.map(r => csvOrder.map(key => {
    if (key == 'action') return t(`reports.action.${r[key]}`)
    if (key == 'occurredAt') return new Date(r[key])
    return r[key]
  }))
  csv.unshift(csvOrder.map(key => t(`reports.header.${key}`))) // add headers
  return csv
}
