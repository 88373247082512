import { useTranslation } from 'react-i18next'
import { useMe } from 'contexts'
import { Button, LoadingSpinner } from 'components'
import { camelize, toFriendlyDate, toFriendlyTime } from 'utils'
import './styles.scss'

export default function UserDetailsScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const { userWithResources, onEditUser, onEditPassword, onResetPasscode } = props
  const { user, resources } = userWithResources || {}

  if (!user) return <LoadingSpinner />
  const roleKey = camelize(user.roleKey) || 'unknown'
  return (
    <div className='user-details-comp col'>
      <div className='header'>
        <h1 className='name'>{`${user.firstName || ''} ${user.lastName || ''}`}</h1>
        <h2>{t(`roles.${roleKey}`)}</h2>
      </div>
      {user.employeeIdentifier &&
       <div>
         <p className='subtitle'>{t('user.employeeID')}</p>
         <p>{user.employeeIdentifier}</p>
       </div>
      }
      {user.email &&
        <div>
          <p className='subtitle'>{t('user.email')}</p>
          <p>{user.email}</p>
        </div>
      }
      {(user.resourceType === 'Store' || user.resourceType === 'Region') &&
       <div>
         <p className='subtitle'>{t('user.location', {count: resources.length})}</p>
         <div className='resources-container'>
           {resources?.map(r => (
             <div className='resource-row' key={r.id}>
               <p>{r.name}</p>
               <p className='role'>{t(`roles.${roleKey}`)}</p>
             </div>
           ))}
         </div>
       </div>
      }
      {user?.lastSignedInAt &&
        <p className='subtitle'>{t('user.lastSignedInAt', { date: toFriendlyDate(user.lastSignedInAt), time: toFriendlyTime(user.lastSignedInAt) })}</p>
      }
      <div className='actions'>
        {user.externallyAuthenticated && <p className='subtitle'>{t('user.externallyAuthenticatedInfo')}</p>}
        {me?.canEditUser && !user.externallyAuthenticated && <Button onClick={onEditUser}>{t('user.editUser')}</Button>}
        {/* This feature is disabled for multi-org users.
        While updating your password as a multi-org user works, it currently responds with a 400 error.
        This should be fixed when the influx changes get merged into the API. */}
        {user.id === me?.id && !me?.hasOrganizations && <Button onClick={onEditPassword}>{user.externallyAuthenticated ? t('profile.editPasscode') : t('profile.editPassword')}</Button>}
        {user.id !== me?.id && me?.canEditUser && <Button onClick={onResetPasscode}>{t('profile.resetPasscode')}</Button>}
      </div>
    </div>
  )
}
