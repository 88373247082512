import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI } from 'global'
import { openModal, PasswordEdit, UserEdit } from 'components'
import Screen from './screen'

export default function UserDetails(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { user, userWithResources: prefetchedUser, onUpdate } = props
  const [userWithResources, setUserWithResources] = useState(prefetchedUser)

  // After navigating back from the edit modals, go ahead and re-open this modal with the new data
  // (or the existing data if there is no new data) and the same callbacks as before
  const reopen = newUserWithResources => openModal(
    <UserDetails
      userWithResources={newUserWithResources || userWithResources}
      onUpdate={onUpdate}
    />
  )

  const editUser = () => openModal(<UserEdit userWithResources={userWithResources} onFinish={reopen} onUpdate={onUpdate} />)
  const editPassword = () => openModal(<PasswordEdit user={user || userWithResources.user} onFinish={reopen} />)
  const resetPasscode = () => api.resetPasscodeByUser(user.id).then(() => toast.success(t('success.request'))).catch(() => toast.error(t('error.request')))

  // Get the selected user and resources on mount.
  // We have to do this because if we go to edit -> details -> edit again, the edit fields will not be updated otherwise.
  useEffect(() => {
    if (!user || prefetchedUser) return
    api.getUser(user.id).then(response => {
      setUserWithResources({
        user: response?.users[0],
        resources: response?.roleResources,
      })
    }).catch(()=>{})
  }, [])

  return (
    <Screen
      userWithResources={userWithResources}
      onEditUser={editUser}
      onEditPassword={editPassword}
      onResetPasscode={resetPasscode}
    />
  )
}
