import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import './styles.scss'

function TextField(props, ref) {
  const { id, label, onEnter, onFocus, onBlur, ...inputProps } = props
  const [isFocused, setIsFocused] = useState(false)
  const field = useRef()

  useImperativeHandle(ref, ()=>({
    get value() { return field.current.value },
    set value(v) { field.current.value = v },
    focus() { field.current.focus() },
  }))

  const onKeyDown = event => {
    if (event.code === 'Enter') onEnter?.()
  }

  const focus = e => {
    setIsFocused(true)
    onFocus?.(e)
  }

  const blur = e => {
    setIsFocused(false)
    onBlur?.(e)
  }

  return (
    <div className="text-field-comp">
      {label && <label className="subtitle" htmlFor={id || label}>{label}{inputProps.required ? ' *' : ''}</label>}
      <input
        id={id || label}
        onKeyDown={onKeyDown}
        aria-label={label}
        placeholder={label}
        type='text'
        { ...inputProps }
        ref={field}
        onFocus={focus}
        onBlur={blur}
      />
      <div id="platform" className={isFocused ? 'focused' : null} />
    </div>
  )
}

export default forwardRef(TextField)
