import { useMobileAPI } from 'global'
import { useLog } from 'components'
import './styles.scss'

export default function LogWindow() {
  const { logs, clear, download, copy } = useLog()
  const mobile = useMobileAPI()

  return (
    <div className='log-window-comp'>
      <div className='logs-container col'>
        {logs?.map((log, index)=>(
          <p key={index}>{log}</p>
        ))}
        <div className='export-actions-container row'>
          {mobile.atLeastVersion({ android: '1.5.0', iOS: '1.5.0' }) &&
            <>
              <i className='material-icons' onClick={download}>{'download'}</i>
              <i className='material-icons' onClick={copy}>{'content_copy'}</i>
            </>
          }
          <i className='material-icons clear-btn' onClick={clear}>{'delete'}</i>
        </div>
      </div>
    </div>
  )
}
