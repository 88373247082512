import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CableType } from 'protoModels/mti/store/cable/state_pb'
import { useStore, useAPI, useMe } from 'global'
import { DeviceType as DT } from 'enums'
import { useDeviceStateFor, iconFor, toHash } from 'utils'
import { Button, UnhealthyIssue as Issue, SecurityDeviceInfo } from 'components'
import DeviceAssignment from './deviceAssignment'
import PortCell from './portCell'
import './styles.scss'

export default function PositionModal(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const api = useAPI()
  // Get the position data from the context, so if it's ever updated, such as by the device
  // assignment, we get notified as well.
  const { position: { id: positionID } } = props
  const { positions, securityDevices, securityDevicesByParent } = useStore()
  const [securedProducts, setSecuredProducts] = useState()
  const [products, setProducts] = useState()
  const [showReassign, setShowReassign] = useState(false)
  const position = positions[positionID]
  const securityDevice = securityDevices[position.securityDeviceId]
  const associatedDevice = securityDevices[securityDevice?.parentId] || securityDevicesByParent[securityDevice?.id]

  // Get the primary device that determines state and contains the radio
  // The primary device that determines state in a puckbase pair is actually the puck, not the base
  let sd = securityDevice
  if (sd?.deviceType == DT.base || sd?.deviceType == DT.fm2Base)
    sd = associatedDevice
  const state = useDeviceStateFor()(sd)

  // Refresh the secured products data whenever the security device changes.
  const refresh = () => {
    if (!sd) setSecuredProducts(null)
    else api.getSecurityDevice(sd.id)
      .then(response => {
        setSecuredProducts(toHash(response?.securedProducts))
        setProducts(toHash(response?.products))
      })
      .catch(()=>toast.error('Could not retrieve secured products'))
  }
  useEffect(() => refresh(), [sd])

  const sendCommand = command => {
    if (!sd) return
    api.sendCommand(command, sd.id)
      .then(()=>toast.success(t('success.request')))
      .catch(()=>toast.error(t('error.request')))
  }

  const wrap = content => (
    <div className='position-modal-comp col'>
      <h1 className='position-name'>{iconFor(state.security)}{position?.name}</h1>
      {content}
    </div>
  )

  // Port Info
  const ports = useMemo(() => {
    if (sd?.deviceType == DT.repeater) return null
    if (![DT.cxFlex, DT.cxFlex1, DT.cxFlex4].includes(sd?.deviceType)) return sd?.ports
    return sd.ports?.filter(p => p.cableType != CableType.PORTNOTSUPPORTED)
  }, [sd])
  const portsInfo = ports?.map(port => {
    const securedProduct = securedProducts?.[port?.securedProductId]
    return (
      <PortCell
        key={port?.address}
        port={port}
        securedProduct={securedProduct}
        securityDevice={sd}
        product={products?.[securedProduct?.productId]}
      />
    )
  })

  // Reassignment selector
  if (showReassign) return wrap(<DeviceAssignment position={position} onFinish={()=>setShowReassign(false)} />)

  // Health Issues
  const alarmingInfo = state.alarming ? <Issue state={state.alarming} /> : null
  const unhealthyInfo = state.unhealthyReason ? <Issue reason={state.unhealthyReason} ports={sd.unhealthyPorts} /> : null

  // Action Buttons
  let action = 'arm'
  if (sd?.alarming && !sd?.silentAlarming) action = 'silence'
  else if (sd?.armed || sd?.silentAlarming) action = 'disarm'
  const actionBtns = (sd && ![DT.lock, DT.repeater].includes(sd.deviceType)) ? (
    <div className='row button-row'>
      <Button onClick={()=>sendCommand('identify')}>{t('position.identify')}</Button>
      <Button onClick={()=>sendCommand(action)}>{t(`position.${action}`)}</Button>
    </div>
  ) : null

  return wrap(
    <>
      {alarmingInfo}
      {unhealthyInfo}
      <SecurityDeviceInfo securityDevice={securityDevice} />
      <SecurityDeviceInfo securityDevice={associatedDevice} />
      {portsInfo}
      {actionBtns}
      {me?.canEditStore && <Button onClick={()=>setShowReassign(true)}>{securityDevice ? t('position.reassign') : t('position.assign') }</Button>}
    </>
  )
}
