import { useEffect, useState, useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useStore, useAPI } from 'global'
import { toFriendlyDate, toFriendlyTime } from 'utils'
import { DeviceType as DT } from 'enums'
import { Button, TextField } from 'components'
import { useTranslation } from 'react-i18next'
import mqtt from 'components/mqtt'

const listenerID = 'deviceAssignment'
const assignableDeviceTypesArray = [
  DT.alarmModule,
  DT.base,
  DT.securePlug,
  DT.cxFlex, // deprecated
  DT.cxFlex1,
  DT.cxFlex4,
  DT.fm2Base,
  DT.lock,
  DT.nxdiBase,
  DT.powerFlex,
  DT.proximityBase,
  DT.puck,
  DT.repeater,
]
const assignableDeviceTypes = assignableDeviceTypesArray.reduce((hash, t) => {
  hash[t] = true
  return hash
}, { })

export default function DeviceAssignment(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { position, onFinish } = props
  const {
    securityDevices,
    securityDevicesBySerial,
    positionsBySecurityDevice,
    saveZoneComponent,
  } = useStore()
  const [selectedID, setSelectedID] = useState(position.securityDeviceId)


  // initialize the filtered list of devices
  const initialList = useMemo(() => Object.values(securityDevices).filter(sd => {
    const pos = positionsBySecurityDevice[sd.id]
    const parentPos = positionsBySecurityDevice[sd.parentId]

    // If the puck is on an addressable base, then the base is assignable, not the puck.
    if (sd.deviceType == DT.puck && sd.parentId) return pos?.id == position.id

    // Otherwise allow the device if it's not already assigned to another position and is an assignable device
    return (!pos && !parentPos && assignableDeviceTypes[sd.deviceType]) || pos?.id == position.id
  }), [])

  // We must setup a reference to our device list so that the mqtt handler can have access to it.
  // Having the handler use the 'devices' state directly would be a bug, because the handler is only
  // setup once at initialization, while the 'devices' state reference will change each time the state
  // is updated.
  const selectedIDRef = useRef()
  selectedIDRef.current = selectedID
  const sortedList = useRef(initialList)
  const [devices, setDevices] = useState(initialList)
  const searchField = useRef()

  // This should not use any state other than references, since it is also referenced in the mqtt
  // listener, which can only use references.
  const search = () => {
    const searchText = searchField.current?.value?.trim().toUpperCase()
    let filteredList = sortedList.current
    if (searchText)
      filteredList = filteredList.filter(sd => sd.id == selectedIDRef.current || sd.serialNumber.toUpperCase().includes(searchText))
    setDevices(filteredList)
  }

  // listen for mqtt messages and move any devices that had a key swipe to the top.
  useEffect(() => {
    mqtt.listen(listenerID, (topic, message) => {
      const identified = getIdentifiedDevice(securityDevicesBySerial, securityDevices, topic, message)
      if (!identified) return

      // Find the identified device and move it to the top of the list
      const idx = sortedList.current.findIndex(d => d.id == identified.id)
      if (idx < 0) return
      const device = sortedList.current.splice(idx, 1)[0]
      sortedList.current.unshift(device)
      search()
    })
    return () => mqtt.unlisten(listenerID)
  }, [])

  const save = () => {
    return api.saveZoneComponent('position', { ...position, securityDeviceId: selectedID }).then(response => {
      const savedResource = response.positions[0]
      saveZoneComponent('position', savedResource)
      onFinish?.()
    }).catch(() => toast.error('failed'))
  }

  return (
    <div className='device-assignment-comp col'>
      <TextField ref={searchField} onChange={search} label={t('global.search')} />
      <div className='list'>
        <div className={`cell ${!selectedID ? 'selected' : ''}`} onClick={()=>setSelectedID(null)}>
          <p>{t('global.unassign')}</p>
        </div>
        {devices.map(sd => (
          <DeviceCell
            key={sd.id}
            selected={selectedID == sd.id}
            onClick={()=>setSelectedID(sd.id)}
            securityDevice={sd}
          />
        ))}
      </div>
      <div className='button-row row'>
        <Button outline onClick={onFinish}>{t('form.cancel')}</Button>
        <Button onClick={save}>{t('form.save')}</Button>
      </div>
    </div>
  )
}

function DeviceCell({ securityDevice: sd, selected, onClick }) {
  const { t } = useTranslation(null, { useSuspense: false })

  return (
    <div className={`cell ${selected ? 'selected' : null}`} onClick={onClick}>
      <p className='serial'>{sd.serialNumber}</p>
      <p>{sd.deviceType}</p>
      <p className='subtitle'>{t('device.createdAt', { date: toFriendlyDate(sd?.createdAt), time: toFriendlyTime(sd?.createdAt) })}</p>
    </div>
  )
}

// Return's the id of an assignable device associated with any key swipe (or null)
// The assignable device isn't always the device that the key was swiped against.
// For example, when a key is swiped against a puck, it's actually the base that the puck is on that
// is assignable to a position, not the puck itself.
function getIdentifiedDevice(devicesBySerial, devicesByID, topic, msg) {
  const device = devicesBySerial[msg.serialNumber]
  let keySwiped
  switch (topic) {
  case 'core_iii/state':
    return (msg.ramStatus?.keyInserted || msg.keyInserted || !/^0*$/.test(msg.keySerialNumber || ''))
      ? device
      : null
  case 'secure_plug/state':
    return msg.ramStatus?.keyInserted ? device : null
  case 'alarm_module/state':
    return msg.keyInserted ? device : null
  case 'nxdi/state':
    return msg.keyInserted ? (devicesBySerial[msg.baseSerialNumber] || device) : null
  case 'proximity_puck/state':
  case 'puck/state':
    return msg.keyInserted ? (devicesByID[device.parentId] || device) : null
  case 'fm_20/state':
    keySwiped = msg.authorizedUserKeyUsed || !/^0*$/.test(msg.keySerialNumber || '')
    return keySwiped ? (devicesByID[device.parentId] || device) : null
  case 'lock/state':
  case 'powerflex/state':
    return (msg.authorizedUserKeyUsed || !/^0*$/.test(msg.keySerialNumber || '')) ? device : null
  }
}
