import { useState, useEffect, createContext, useContext } from 'react'
import { useMobileAPI } from 'global'
import { confirm } from 'components'

const Context = createContext()
const listenerID = 'LogProvider'

export function LogProvider(props) {
  const mobile = useMobileAPI()
  const [logs, setLogs] = useState([])

  useEffect(() => {
    mobile.listen(listenerID, (event, payload) => {
      if (event != 'log' && event != 'error') return
      const timestamp = new Date().toTimeString().split(' ')[0]
      if (event == 'error' && typeof payload == 'object' && payload.message && payload.stacktrace) {
        const formattedLog = `[${timestamp}] ${payload.message}`
        setLogs(prev => [...prev, formattedLog, payload.stacktrace])
      } else {
        const formattedLog = `[${timestamp}] ${payload}`
        setLogs(prev => [...prev, formattedLog])
      }
    })
    return () => mobile.unlisten(listenerID)
  }, [])

  // TODO: add log methods for client-side logging as well
  const value = {
    logs,
    clear: () => confirm(() => setLogs([])),
    download: () => mobile.post('downloadLogs', JSON.stringify(logs)),
    copy: () => mobile.post('copyLogs', JSON.stringify(logs)),
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useLog() {
  return useContext(Context)
}
